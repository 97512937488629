<template>
  <validation-observer ref="create-client-profile">
    <b-form class="p-2" @submit.prevent>
      <b-row>
        <b-col sm="12" md="6">
          <b-row>
            <b-col v-show="numberVisible" sm="6">
              <b-form-group label="Número" label-for="client-number">
                <b-form-input
                  :value="number"
                  name="client-number"
                  placeholder="Número"
                  type="number"
                  plaintext
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <validation-provider
                v-slot="validationContext"
                name="CIF"
                rules="required|min:9"
              >
                <b-form-group label="CIF" label-for="client-cif">
                  <b-form-input
                    :value="model.cif"
                    name="client-cif"
                    placeholder="cif"
                    :state="$getValidationState(validationContext)"
                    @keydown="checkInput"
                    @input="update('cif', $event)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                v-slot="validationContext"
                name="Nombre fiscal"
                rules="required"
              >
                <b-form-group
                  label="Nombre fiscal"
                  label-for="client-fiscal-name"
                >
                  <b-form-input
                    :value="model.name"
                    name="client-fiscal-name"
                    placeholder="Nombre fiscal"
                    :state="$getValidationState(validationContext)"
                    @input="update('name', $event)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                v-slot="validationContext"
                name="Nombre comercial"
              >
                <b-form-group
                  label="Nombre comercial"
                  label-for="client-commercial-name"
                >
                  <b-form-input
                    :value="model.commercial_name"
                    name="client-commercial-name"
                    placeholder="Nombre comercial"
                    :state="$getValidationState(validationContext)"
                    @input="update('commercial_name', $event)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Teléfono" label-for="client-phone">
                <b-form-input
                  :value="model.phone"
                  name="client-phone"
                  placeholder="Teléfono"
                  @input="update('phone', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <validation-provider
                v-slot="validationContext"
                name="Código postal"
                rules="required"
              >
                <b-form-group label="CP" label-for="postal-code">
                  <b-form-input
                    :value="model.post_code"
                    name="postal-code"
                    type="number"
                    placeholder="Código postal"
                    :state="$getValidationState(validationContext)"
                    @input="update('post_code', $event)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Dirección"
                rules="required"
              >
                <b-form-group label="Dirección" label-for="client-address">
                  <b-form-input
                    :value="model.address"
                    name="client-address"
                    placeholder="Direción"
                    :state="$getValidationState(validationContext)"
                    @input="update('address', $event)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                v-slot="validationContext"
                name="País"
                rules="required"
              >
                <b-form-group label="País" label-for="client-country">
                  <BaseSelect
                    id="client-country"
                    :value="model.country"
                    http-method="get"
                    :resource="$data.$constants.RESOURCES.RESOURCE_COUNTRIES"
                    @input="handleCountryInput"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Provincia" label-for="client-province">
                <v-select
                  label="name"
                  :value="model.province"
                  :options="provinceOptions"
                  @option:selected="update('province', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <validation-provider
                v-slot="validationContext"
                name="Ciudad"
                rules="required"
              >
                <b-form-group label="Ciudad" label-for="client-city">
                  <b-form-input
                    :value="model.population"
                    name="client-city"
                    placeholder="Ciudad"
                    :state="$getValidationState(validationContext)"
                    @input="update('population', $event)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" md="6">
          <b-row>
            <b-col sm="12">
              <b-form-group label="Email" label-for="client-email">
                <b-form-input
                  :value="model.email"
                  name="client-email"
                  placeholder="Email"
                  @input="update('email', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Estado" label-for="client-status">
                <StatusSelect
                  :value="model.status"
                  :type="statusType"
                  @input="update('status', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Observaciones"
                label-for="client-observations"
              >
                <b-form-textarea
                  :value="model.observations"
                  name="client-observations"
                  placeholder="Observaciones"
                  rows="4"
                  max-rows="4"
                  @input="update('observations', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Imagen cliente" label-for="client-image">
                <BaseImageFileInput
                  id="provider-image"
                  :value="clientImage"
                  @input="update('image', [$event])"
                  @delete-image="imageRemoved = true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from "@/mixins/formMixin";
import { CLIENT_STATUS_TYPE } from "@/api/status-api";
import StatusSelect from "@/components/status/select/StatusSelect.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import BaseImageFileInput from "@/components/ui/file/BaseImageFileInput.vue";
import ProvincesApi from "@/api/provinces-api";

export default {
  name: "ClientProfileForm",
  components: {
    BaseImageFileInput,
    BaseSelect,
    StatusSelect,
  },
  mixins: [FormMixin],
  props: {
    numberVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statusType: CLIENT_STATUS_TYPE,
      imageRemoved: false,
      provinceOptions: [],
    };
  },
  watch: {
    async "model.country"(value) {
      const response = await ProvincesApi.list({
        page: 1,
        filters: { country_id: value.id },
      });
      this.provinceOptions = response.data;
    },
  },
  computed: {
    number() {
      return this.model ? this.model.number : null;
    },
    clientImage() {
      if (this.model.image) {
        return this.model.image.path || this.model.image;
      }

      return null;
    },
    countryId() {
      return this.model?.contry?.id;
    },
  },
  methods: {
    validateForm() {
      return this.$refs["create-client-profile"].validate();
    },
    isImageRemoved() {
      return this.imageRemoved;
    },
    async handleCountryInput(country) {
      this.update("country", country);
    },
    checkInput(event) {
      // Definimos los caracteres permitidos: letras, números, y guiones
      const regex = /^[a-zA-Z0-9]+$/;
      const key = event.key;

      // Permitimos teclas especiales (borrar, tab, etc.)
      if (event.keyCode === 8 || event.keyCode === 9 || event.keyCode === 37 || event.keyCode === 39) {
        return true;
      }

      // Si la tecla presionada no pasa el regex, cancelamos la entrada
      if (!regex.test(key)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped></style>

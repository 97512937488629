<template>
  <b-row
    align-v="center"
    no-gutters
  >
    <b-col>
      <BaseSelect
        :id="id"
        :value="value"
        :resource="$data.$constants.RESOURCES.RESOURCE_CLIENTS"
        class="base-action-select"
        :disabled="disabled"
        @input="$emit('input', $event)"
        @selected="$emit('selected', $event)"
      />
    </b-col>
    <b-col
      v-show="!disabled"
      v-access="{
        resource: $data.$constants.RESOURCES.RESOURCE_CLIENTS,
        resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
      }"
      cols="auto"
    >
      <b-button
        variant="primary"
        class="text-nowrap rounded-right d-inline-block base-select-add-button"
        @click="creationModalVisible = true"
      >
        <feather-icon
          icon="PlusIcon"
          size="19"
        />
      </b-button>
    </b-col>
    <ClientCreationModal
      v-model="creationModalVisible"
      @client-created="$emit('input', $event)"
    />
  </b-row>
</template>

<script>
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import ClientCreationModal from '@/components/clients/modal/ClientCreationModal.vue'

export default {
  name: 'ClientSelect',
  components: { ClientCreationModal, BaseSelect },
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: [Object, String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      creationModalVisible: false,
    }
  },
}
</script>

<style scoped>
</style>

<template>
  <validation-observer ref="create-client-billing">
    <b-form
      ref="client-billing-form"
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Forma de pago"
              >
                <b-form-group
                  label="Forma de pago"
                  label-for="client-type-payment"
                >
                  <BaseSelect
                    id="client-type-payment"
                    :value="model.type_payment"
                    :resource="$data.$constants.RESOURCES.RESOURCE_TYPE_PAYMENTS"
                    @input="update('type_payment', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="IBAN"
                label-for="client-iban"
                >
                  <b-form-input
                    :value="model.iban"
                    name="client-iban"
                    placeholder="IBAN"
                    maxlength="30"
                    @input="update('iban', $event)"
                  />
                  </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="SWIFT"
                label-for="client-swift"
              >
                <b-form-input
                  :value="model.swift"
                  name="client-swift"
                  placeholder="SWIFT"
                  @input="update('swift', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
                <b-form-group
                  label="IRPF"
                  label-for="client-irpf"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      :value="model.irpf_percent"
                      name="client-irpf"
                      placeholder="IRPF"
                      type="number"
                      @input="update('irpf_percent', $event)"
                    />
                    <b-input-group-append is-text>
                      <feather-icon icon="PercentIcon" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-row align-h="center">
            <b-col sm="6">
                <b-form-group
                  label="IVA"
                  label-for="client-iva"
                >  <TaxesSelect
                    :value="model.tax"
                    @input="update('tax', $event)"
                  />
                </b-form-group>

            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Recargo"
                label-for="client-recharge"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    :value="model.surcharge"
                    name="client-recharge"
                    placeholder="Recargo"
                    type="number"
                    @input="update('surcharge', $event)"
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="PercentIcon" />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              sm="11"
              class="border-top billing-divider"
            />
          </b-row>
          <b-row>
            <b-col sm="6">
                <b-form-group
                  label="Límite de riesgo"
                  label-for="client-limit"
                >
                  <b-form-input
                    :value="model.financial_limit"
                    name="client-limit"
                    placeholder="Límite riesgo"
                    type="number"
                    @input="update('financial_limit', $event)"
                  />
                </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import TaxesSelect from '@/components/tax/select/TaxesSelect.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'ClientBillingForm',
  components: { BaseSelect, TaxesSelect },
  mixins: [FormMixin],
  methods: {
    validateForm() {
      return this.$refs['create-client-billing'].validate()
    },
  },
}
</script>

<style scoped>
.billing-divider {
  margin: 39px 0;
}
</style>
